import React, {
	useCallback,
	useContext,
	useEffect,
	useState
} from 'react';
import {
	Card,
	CardContent,
	CardHeader,
	SelectChangeEvent,
	useMediaQuery,
	useTheme,
} from '@mui/material';
import 'react-quill/dist/quill.snow.css';
import {useTranslation} from 'react-i18next';
import {
	greenChoiceCardMediumSX,
	greenChoiceCardSX
} from '../GreenChoiceSettings';
import {
	FlagUtil,
	StringUtil
} from '@app/util';
import {
	AlertSeverity,
	Flag,
	GreenChoiceDescription,
	GreenChoiceDescriptionOption,
	GreenChoiceDescriptionView,
	LanguageName,
	ResolvedLanguage,
	TabDetails,
} from '@app/model';
import {CustomTabs} from '@app/shared';
import {GreenChoiceService} from '@app/services';
import {
	SnackbarContext,
	UserContext
} from '@app/context';
import * as DOMPurify from 'dompurify';
import _ from 'lodash';
import {GreenChoiceCustomDescriptionCardContent} from './content/GreenChoiceCustomDescriptionCardContent';
import {GreenChoiceCustomDescriptionCardSubmitButton} from './submit-button/GreenChoiceCustomDescriptionCardSubmitButton';
import {GreenChoiceCustomDescriptionLanguageSelect} from './language-select-options/GreenChoiceCustomDescriptionLanguageSelect';

interface GreenChoiceDescriptionCardProps {
	availableLanguages: string[];
}

export function GreenChoiceCustomDescriptionCard(props: GreenChoiceDescriptionCardProps) {
	const {availableLanguages} = props;
	const {t, i18n} = useTranslation(['tenantAdmin', 'common', 'greenChoice']);
	const theme = useTheme();
	const isMediumScreen = useMediaQuery(theme.breakpoints.down('md'));

	const userContext = useContext(UserContext);

	const [isDescriptionLoading, setIsDescriptionLoading] = useState<boolean>(true);
	const [selectedView, setSelectedView] = useState<GreenChoiceDescriptionView>(
		GreenChoiceDescriptionView.GUEST_PAGE
	);
	const [selectedLanguage, setSelectedLanguage] = useState<LanguageName>(
		StringUtil.mapLanguageName(i18n.language)
	);
	const [guestDescription, setGuestDescription] = useState<GreenChoiceDescription[]>([]);
	const [guestEditedDescription, setGuestEditedDescription] = useState<string>('');
	const [guestBottomTextEdited, setGuestBottomTextEdited] = useState<string>('');
	const [voucherDescription, setVoucherDescription] = useState<GreenChoiceDescription[]>([]);
	const [voucherLabelEdited, setVoucherLabelEdited] = useState<string>('');
	const [voucherEditedDescription1, setVoucherEditedDescription1] = useState<string>('');
	const [voucherEditedDescription2, setVoucherEditedDescription2] = useState<string>('');
	const [voucherLabelCustom1Edited, setVoucherLabelCustom1Edited] = useState<string>('');
	const [isSavePossible, setIsSavePossible] = useState<boolean>(false);
	const [isVoucherHeightAllowed, setIsVoucherHeightAllowed] = useState<boolean>(true);
	const [isButtonLoading, setIsButtonLoading] = useState<boolean>(false);

	const greenChoiceService = GreenChoiceService.get();
	const {showMessage} = useContext(SnackbarContext);

	const isGreenChoiceVoucherOn = FlagUtil.hasEnabledFlag(userContext.loggedInUser, Flag.GREEN_CHOICE_DIGITAL_VOUCHER);

	const contentData = {
		bottomTextEdited: guestBottomTextEdited,
		voucherDescription: voucherDescription,
		editedDescription1: voucherEditedDescription1,
		editedDescription2: voucherEditedDescription2,
		isDescriptionLoading: isDescriptionLoading,
		isVoucherHeightAllowed: isVoucherHeightAllowed,
		guestEditedDescription: guestEditedDescription,
		voucherLabelEdited: voucherLabelEdited,
		voucherLabelCustom1Edited: voucherLabelCustom1Edited,
	};
	const contentActions = {
		setGuestDescriptionEdited: setGuestEditedDescription,
		setIsDescriptionLoading: setIsDescriptionLoading,
		setIsVoucherHeightAllowed: setIsVoucherHeightAllowed,
		setEditedDescription1: setVoucherEditedDescription1,
		setEditedDescription2: setVoucherEditedDescription2,
		setBottomTextEdited: setGuestBottomTextEdited,
		setVoucherLabelEdited: setVoucherLabelEdited,
		setVoucherLabelCustom1Edited: setVoucherLabelCustom1Edited,
	};

	const getGreenChoiceDescription = useCallback(
		(language: ResolvedLanguage, options: GreenChoiceDescriptionOption[]) => {
			greenChoiceService
				.getGreenChoiceDescription(language, options)
				.then((res: GreenChoiceDescription[]) => {
					const sanitizedRes = res.map((obj) => {
						obj.label = DOMPurify.sanitize(obj.label);
						return obj;
					});

					if (options.includes(GreenChoiceDescriptionOption.GUEST)) {
						handleGetGuestDescription(sanitizedRes, language);
					} else {
						setVoucherDescription(sanitizedRes);
						setVoucherEditedDescription1(sanitizedRes[0].label);
						setVoucherEditedDescription2(sanitizedRes[1].label);
					}

					setIsDescriptionLoading(false);
					setIsSavePossible(false);
				})
				.catch(() => {
					setIsDescriptionLoading(false);
					showMessage(t('common:error.commonError'), AlertSeverity.ERROR);
				});
		},
		[greenChoiceService]
	);

	useEffect(() => {
		if (
			voucherDescription[0]?.label === voucherEditedDescription1 &&
			voucherDescription[1]?.label === voucherEditedDescription2 &&
			isVoucherHeightAllowed
		) {
			setIsSavePossible(false);
		} else {
			if (!isSavePossible) {
				setIsSavePossible(true);
			}
		}
	}, [voucherEditedDescription1, voucherEditedDescription2, isVoucherHeightAllowed]);

	useEffect(() => {
		console.log("0: " + guestDescription[0]?.label + ", " + guestEditedDescription)
		console.log("1: " + guestDescription[1]?.label + ", " + guestBottomTextEdited)
		console.log("2: " + guestDescription[2]?.label + ", " + voucherLabelEdited)
		console.log("3: " + guestDescription[3]?.label + ", " + voucherLabelCustom1Edited)
		if (
			guestDescription[0]?.label === guestEditedDescription &&
			guestDescription[1]?.label === guestBottomTextEdited &&
			guestDescription[2]?.label === voucherLabelEdited &&
			guestDescription[3]?.label === voucherLabelCustom1Edited
		) {
			setIsSavePossible(false);
		} else {
			if (!isSavePossible) {
				setIsSavePossible(true);
			}
		}
	}, [guestEditedDescription, guestBottomTextEdited]);

	useEffect(() => {
		getGreenChoiceDescription(
			StringUtil.mapResolvedLanguage(selectedLanguage),
			selectedView === GreenChoiceDescriptionView.GUEST_PAGE
				? [
					GreenChoiceDescriptionOption.GUEST,
					GreenChoiceDescriptionOption.GUEST_BOTTOM_TEXT,
					GreenChoiceDescriptionOption.VOUCHER_LABEL,
					GreenChoiceDescriptionOption.VOUCHER_LABEL_CUSTOM_1,
				]
				: [GreenChoiceDescriptionOption.VOUCHER1, GreenChoiceDescriptionOption.VOUCHER2]
		);
	}, [getGreenChoiceDescription, selectedView]);

	function getTabsList() {
		const tabList: TabDetails[] = [
			{
				label: t('greenChoice:settings.description.guestPage.title'),
				value: GreenChoiceDescriptionView.GUEST_PAGE,
			},
		];
		if (isGreenChoiceVoucherOn) {
			const voucherTabDetails: TabDetails = {
				label: t('greenChoice:settings.description.voucher.title'),
				value: GreenChoiceDescriptionView.VOUCHER,
			};

			tabList.push(voucherTabDetails);
		}

		return tabList;
	}

	function handleTabChange(event: React.SyntheticEvent, newValue: GreenChoiceDescriptionView) {
		setSelectedView(newValue);
	}

	function handleGetGuestDescription(res: GreenChoiceDescription[], language: ResolvedLanguage) {
		const guestDescription: GreenChoiceDescription[] = [];

		const description: GreenChoiceDescription = res[0];
		const bottomText: GreenChoiceDescription = res[1];
		const voucherLabel: GreenChoiceDescription = res[2];
		const voucherLabelCustom1: GreenChoiceDescription = res[3];

		if (description.label) {
			guestDescription.push({
				label: description.label,
				option: GreenChoiceDescriptionOption.GUEST,
			});
			setGuestEditedDescription(_.cloneDeep(description.label));
		} else {
			const defaultDescription = t(
				'greenChoice:guestView.activationPage.defaultDescription',
				{
					lng: language,
				}
			);
			guestDescription.push({
				label: `<p>${defaultDescription}</p>`,
				option: GreenChoiceDescriptionOption.GUEST,
			});
			setGuestEditedDescription(`<p>${defaultDescription}</p>`);
			setGuestDescription(guestDescription);
		}

		guestDescription.push({
			label: voucherLabel.label ? voucherLabel.label : '<p>Voucher</p>',
			option: GreenChoiceDescriptionOption.VOUCHER_LABEL,
		})

		setVoucherLabelEdited(voucherLabel.label);

		guestDescription.push({
			label: voucherLabelCustom1.label ? voucherLabelCustom1.label : '<p>Custom 1</p>',
			option: GreenChoiceDescriptionOption.VOUCHER_LABEL_CUSTOM_1,
		})

		setVoucherLabelCustom1Edited(voucherLabelCustom1.label);

		guestDescription.push({
			label: bottomText.label ? bottomText.label : `<p><br></p>`,
			option: GreenChoiceDescriptionOption.GUEST_BOTTOM_TEXT,
		});

		setGuestBottomTextEdited(bottomText ? _.cloneDeep(bottomText.label) : `<p><br></p>`);
	}

	function handleSubmit() {
		setIsButtonLoading(true);
		const editedDescriptionObj: GreenChoiceDescription[] = [];
		if (selectedView === GreenChoiceDescriptionView.GUEST_PAGE) {
			editedDescriptionObj.push({
				option: GreenChoiceDescriptionOption.GUEST,
				label: guestEditedDescription,
			});
			editedDescriptionObj.push({
				option: GreenChoiceDescriptionOption.GUEST_BOTTOM_TEXT,
				label: guestBottomTextEdited,
			});
			editedDescriptionObj.push({
				option: GreenChoiceDescriptionOption.VOUCHER_LABEL,
				label: voucherLabelEdited,
			});
			editedDescriptionObj.push({
				option: GreenChoiceDescriptionOption.VOUCHER_LABEL_CUSTOM_1,
				label: voucherLabelCustom1Edited,
			});

		} else {
			editedDescriptionObj.push(
				{
					option: GreenChoiceDescriptionOption.VOUCHER1,
					label: voucherEditedDescription1,
				},
				{
					option: GreenChoiceDescriptionOption.VOUCHER2,
					label: voucherEditedDescription2,
				}
			);
		}

		greenChoiceService
			.setGreenChoiceDescription(
				StringUtil.mapResolvedLanguage(selectedLanguage),
				editedDescriptionObj
			)
			.then(() => {
				setIsButtonLoading(false);
				showMessage(t('common:save.saved'), AlertSeverity.SUCCESS);
				setIsSavePossible(false);
			})
			.catch(() => {
				setIsButtonLoading(false);
				showMessage(t('common:error.commonError'), AlertSeverity.ERROR);
			});
	}

	function handleChange(e: SelectChangeEvent) {
		const newLanguage = e.target.value;
		setIsDescriptionLoading(true);
		setSelectedLanguage(StringUtil.mapLanguageName(newLanguage));
		if (selectedView === GreenChoiceDescriptionView.GUEST_PAGE) {
			getGreenChoiceDescription(StringUtil.mapResolvedLanguage(newLanguage), [
				GreenChoiceDescriptionOption.GUEST,
				GreenChoiceDescriptionOption.GUEST_BOTTOM_TEXT,
				GreenChoiceDescriptionOption.VOUCHER_LABEL,
				GreenChoiceDescriptionOption.VOUCHER_LABEL_CUSTOM_1,
			]);
		} else {
			getGreenChoiceDescription(StringUtil.mapResolvedLanguage(newLanguage), [
				GreenChoiceDescriptionOption.VOUCHER1,
				GreenChoiceDescriptionOption.VOUCHER2,
			]);
		}
	}

	return (
		<Card variant="outlined" sx={isMediumScreen ? greenChoiceCardMediumSX : greenChoiceCardSX}>
			<CardHeader title={t('greenChoice:settings.description.title')}/>
			<CardContent>
				<CustomTabs
					tabsValue={selectedView}
					tabList={getTabsList()}
					onChange={handleTabChange}
				/>
				<GreenChoiceCustomDescriptionLanguageSelect
					isDescriptionLoading={isDescriptionLoading}
					availableLanguages={availableLanguages}
					selectedLanguage={selectedLanguage}
					handleChange={handleChange}
				/>
				<GreenChoiceCustomDescriptionCardContent
					selectedView={selectedView}
					selectedLanguage={selectedLanguage}
					actions={contentActions}
					data={contentData}
				/>
				<GreenChoiceCustomDescriptionCardSubmitButton
					isVoucherHeightAllowed={isVoucherHeightAllowed}
					isButtonLoading={isButtonLoading}
					isSavePossible={isSavePossible}
					handleSubmit={handleSubmit}
				/>
			</CardContent>
		</Card>
	);
}
